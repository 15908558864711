const personalInfo = {
  firstName: "Beatriz",
  lastName: "Arantes",
  linkedin: "beatrizarants",
  website: "https://beatrizarant.es",
  age: 26,
  email: "biarantes@protonmail.com",
  mobile: "(11) 97260-0192",
  location: "Vila Mascote, São Paulo",
  summary: "Profissional com expertise em comunicação e marketing digital há 6 anos. Atuação como marketing digital focado em planejamento e estratégia de mídia com expertise em social ads"
}

// Description also accepted
const experienceList = [
  {
    start: "Out 2021",
    end: "Presente",
    company: "Skelt Beauty Brands",
    title: "Analista Pleno de Marketing de Performance",
    description: "",
    highlights: [
      "Gerenciamento e otimização de campanhas das marcas Creamy Skincare e Skelt Cosméticos, nos canais (Facebook, Google, Tiktok, Pinterest e Mercado Livre)",
      "Extração de dados, análise e apresentação de análises semanais para o time de marketing, com o objetivo de gerar insights para todas as áreas da empresa",
      "Insights e ações executadas com base nos KPI’s de campanhas e performance diárias",
      "Patrocínio de conteúdo de influenciadores digitais",
      "Criação e validação de testes AB para refinamento de campanhas",
      "Busca por novos canais de aquisição",
      "Briefing de peças para campanhas junto ao time de designers",
      "Demandas de conteúdo para o time de influência digital",
      "Contato com time técnico do Google, Facebook, Tiktok e Pinterest",
      "Reuniões semanais com a consultoria de performance",
    ]
  },
  {
    start: "Mai 2021",
    end: "Out 2021",
    company: "Westwing Brasil",
    title: "Analista Pleno de Marketing de Performance",
    description: "",
    highlights: [
      "Planejamento e estratégia de anúncios para Westwing Club, Westwing Now e Mezzanine",
      "Acompanhamento dos anúncios nos canais (Facebook, Google, Pinterest, mídia programática e Tik Tok)",
      "Análise de performance dos anúncios (ROI, CPC, CTR e leads)",
      "Reuniões semanais de alinhamento com a agência de performance",
      "Briefing de peças para campanhas junto ao time de designers",
      "Apresentação mensal de resultados para o time de marketing",
      "Gerenciamento dos anúncios de Mezzanine dentro dos markeplaces (B2W e Magalu)",
      "Acompanhamento do resultado de vendas de Mezzanine junto ao time comercial",
      "Criaçao de relatórios no Data Studio ",
    ]
  },
  {
    start: "Ago 2020",
    end: "Mai 2021",
    company: "Agência GDM",
    title: "Analista Pleno de Marketting de Performance",
    description: "",
    highlights: [
      "Contas: CNA Franquias, Escola Panamericana de Arte e Design, Allianz Parque e Lady",
      "Desenvolvimento de planos de mídia digital",
      "Implementação, monitoramento e otimização de campanhas (Facebook Ads e Google Ads, Linkedin Ads e Taboola)",
      "Acompanhamento e análise dos KPI’s",
      "Elaboração de relatórios de campanhas com os resultados, métricas e estratégias",
      "Otimização dos resultados dos canais de mídia (performance)",
      "Controle de budgets e pagamentos",
      "Desenvolvimento de landing pages e email marketing no RD Station",
      "Acompanhamento dos resultados de Inbound Marketing no RD Station ",
    ]
  },
  {
    start: "Mai 2019",
    end: "Jun 2020",
    company: "Pausa na carreira para desenvolvimento profissional",
    title: "Intercâmbio em Sydney - Austrália",
    description: "",
    highlights: [
      "6 meses: diploma de inglês avançado",
      "6 meses: diploma de Social Media Marketing",
    ]
  },
  {
    start: "Jun 2018",
    end: "May 2019",
    company: "Agência GDM",
    title: "Analista Júnior de Marketing Digital",
    description: "",//"Responsável por planejar, executar, e otimizar campanhas de mídia digital (Facebook, Google, e Linkedin Ads), desenvolver estratégias de conteúdo para redes sociais. estratégias de inbound marketing e cuidar do relacionamento com veículos de mídia.",
    highlights: [
      "Contas: Escola Panamericana de Arte e Design, Editora Moderna e Grupo de Mídia",
      "Desenvolvimento e execução de estratégias digitais;",
      "Planejamento e desenvolvimento de estratégias de conteúdo para redes sociais (Instagram, Facebook, Blog e Youtube);",
      "Planejamento da estratégia de inbound marketing; ",
      "Desenvolvimento e disparo de e-mail marketing e automação; ",
      "Desenvolvimento de landing pages no RD Station; ",
      "Planejamento e otimização de campanhas de mídia para Facebook e Google Ads;",
      "Relacionamento com veículos de mídia;",
      "Desenvolvimento de relatórios mensais e análise de resultados.",
    ]
  },
  {
    start: "Mai 2017",
    end: "Jun 2018",
    company: "Alphaville Urbanismo",
    title: "Estagiária de Marketing Digital",
    description: "",
    highlights: [
      // "Gerenciamento de conteúdo dos canais digitais da marca",
      // "Desenvolvimento de campanhas e projetos institucionais",
      // "Análise e acompanhamento de resultados",
      // "Desenvolvimento de relatórios",
      // "Manutenção de conteúdo do site institucional",
      // "Relacionamento com agências e fornecedores",
      // "Suporte aos processos financeiros dentro do sistema SAP",
      // "Projetos: Implementação do novo site institucional, Alpha Inova, Alpha na Estrada, Urban21 e Alpha Labs",
    ]
  },
  {
    start: "Set 2016",
    end: "Abr 2017",
    company: "F&Q Brazil - Mc Saatchi Group",
    title: "Estagiária de Atendimento",
    description: "",
    highlights: []
  },
  {
    start: "Mar 2016",
    end: "Set 2016",
    company: "Agência Mercado Jovem",
    title: "Estagiária de Atendimento",
    description: "",
    highlights: []
  },
]

const educationList = [
  {
    start: "2014",
    end: "2018",
    certification: "Bacharelado em Publicidade e Propaganda com especialização em Marketing",
    school: "Universidade Presbiteriana Mackenzie"
  }
]

const courseList = [
  {
    start: "Jun",
    end: "Jun 2022",
    certification: "Inovaçōes e Tendências no Ecommerce - dos Marketplaces ao Metaverso e NFT",
    school: "ESPM"
  },
  {
    start: "Jan",
    end: "Jun 2020",
    certification: "Social Media Marketing",
    school: "Mega School",
    location: "Intercâmbio Sydney"
  },
  {
    start: "Mai",
    end: "Nov 2019",
    certification: "Inglês (Avançado)",
    school: "Ability English School",
    location: "Intercâmbio Sydney"
  },
  {
    start: "2019",
    end: "2019",
    certification: "Growth Marketing",
    school: "Udemy"
  },
  {
    start: "2018",
    end: "2018",
    certification: "Inbound Marketing",
    school: "HubSpot Academy"
  },
  {
    start: "2016",
    end: "2016",
    certification: "Comunicação Integrada",
    school: "ESPM"
  },
]

const toolList = [
  "Microsoft Office",
  "Google Analytics",
  "Ferramentas de Anúncios",
  "Data Studio",
  "Trello",
  "RD Station",
  "WordPress",
  "SAP",
]

export { experienceList, educationList, courseList, toolList, personalInfo }
